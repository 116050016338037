import _inherits from "inherits";
import _md from "md5.js";
import _ripemd from "ripemd160";
import _sha from "sha.js";
import _cipherBase from "cipher-base";
var exports = {};
var inherits = _inherits;
var MD5 = _md;
var RIPEMD160 = _ripemd;
var sha = _sha;
var Base = _cipherBase;

function Hash(hash) {
  Base.call(this, "digest");
  this._hash = hash;
}

inherits(Hash, Base);

Hash.prototype._update = function (data) {
  this._hash.update(data);
};

Hash.prototype._final = function () {
  return this._hash.digest();
};

exports = function createHash(alg) {
  alg = alg.toLowerCase();
  if (alg === "md5") return new MD5();
  if (alg === "rmd160" || alg === "ripemd160") return new RIPEMD160();
  return new Hash(sha(alg));
};

export default exports;